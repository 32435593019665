<template>
  <div class="login">
    <h1 class="loginlogo"><img src="@/assets/img/play_moka.svg" /></h1>
    <div class="login-con">
      <div class="login-in">
        <!--h2>{{$t('front.gnb.hello')}}</h2>
        <span>{{$t('front.gnb.login')}}</span-->
        <input class="logininput" type="text" :placeholder="$t('front.common.memId')" v-model="model.memId"/>
        <input class="logininput" type="password" :placeholder="$t('front.common.password')" v-model="model.memPass" @keyup.enter="onSubmit"/>
        <section class="captcha-box">
          <VueClientRecaptcha
            :value="inputValue"
            :count="4"
            chars="12345"
            :hideLines="true"
            custom-text-color="black"
            @getCode="getCaptchaCode"
            @isValid="checkValidCaptcha"
          >
            <template>
              <span style="color: blue">with Custom Text Or Icon</span>
            </template>
          </VueClientRecaptcha>
          <input
            v-model="inputValue"
            placeholder="자동입력방지코드"
            class="input"
            type="text"
          />
        </section>
        <!--a class="findbtn">{{$t('front.common.memId')}}/{{$t('front.common.password')}} {{$t('front.common.find')}}</a-->
        <a class="loginnbtn" @click="onSubmit">{{$t('front.login.loginButton')}}</a>
        <p class="joinnbtnwrap">{{$t('front.login.notJoin')}} <a class="joinnbtn" @click="goPageByName('mobileJoin')">{{$t('front.common.signup')}}</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import '@/styles_mobile/login.css'
import { signIn } from '@/api/member'
import { setAuthData } from '@/libs/auth-helper'
import store from '@/store'

import VueClientRecaptcha from 'vue-client-recaptcha'

export default {
  name: 'login',
  components: {
    VueClientRecaptcha
  },
  data () {
    return {
      model: {
        memId: '',
        memPass: ''
      },
      data: {
        captchaCode: null,
        isValid: false
      },
      inputValue: null
    }
  },
  computed: {
    canSubmit () {
      return this.model.memId && this.model.memPass
    }
  },
  methods: {
    getCaptchaCode (value) {
      this.data.captchaCode = value
    },
    checkValidCaptcha (value) {
      this.data.isValid = value
    },
    getMemId (value) {
      this.model.memId = value
    },
    getMemPass (value) {
      this.model.memPass = value
    },
    setReloadCaptcha () {
      setTimeout(() => {
        if (document.querySelector('.vue_client_recaptcha_icon')) {
          document.querySelector('.vue_client_recaptcha_icon').click()
        }
      }, 500)
    },
    async onSubmit () {
      if (!this.data.isValid) {
        await this.onAlert('warningart', '자동 입력 방지 코드를 확인해주세요.')
        setTimeout(() => {
          if (document.querySelector('.vue_client_recaptcha_icon')) {
            document.querySelector('.vue_client_recaptcha_icon').click()
          }
        }, 500)

        return false
      }

      let reloadCaptcha = false

      if (this.model.memId === '') {
        this.onAlert('warningart', 'front.signin.emptyMemId')
        reloadCaptcha = true
      }
      if (this.model.memPass === '') {
        this.onAlert('warningart', 'front.signin.emptyMemPass')
        reloadCaptcha = true
      }

      if (!this.canSubmit) {
        reloadCaptcha = true
      }

      if (reloadCaptcha) {
        this.setReloadCaptcha()
        return false
      }

      /*
      * api/member.js 참고
      */
      signIn(this.model).then(async response => {
        const result = response.data
        const data = result.data
        if (result.resultCode === '0') {
          setAuthData(data)
          store.commit('setUserData', data.member)
          store.dispatch('storeCommonCode').then(commonCode => {
            this.replacePageByName('mobileMain')
          })
        } else if (result.resultCode === 'U001' || result.resultCode === 'LX02') {
          await this.onCheck('err.' + result.resultCode)
          this.setReloadCaptcha()
        } else {
          await this.onCheck('로그인정보가 올바르지 않습니다. 확인해주세요.')
          this.setReloadCaptcha()
        }
      }).catch(err => {
        console.error('login err : ', err.message)
        if (err.message === 'no member') {
          // alert('로그인정보가 올바르지 않습니다. 확인해주세요.')
          this.onCheck('로그인정보가 올바르지 않습니다. 확인해주세요.')
          this.setReloadCaptcha()
        } else {
          alert(err.message)
        }
      })
    }
  }
}
</script>
<style scoped>
.captcha-box { margin-top: 15px; }
.captcha-box .vue_client_recaptcha {
  background-color: #fff;
  width: 350px;
  display: inline-block;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
  text-align: center;
}

/deep/ .captcha-box .vue_client_recaptcha .vue_client_recaptcha_icon {
  float: right;
  display: inline-block;
}

.captcha-box .input {
  position: relative;
  width: 350px;
  height: 50px;
  text-align: center;
  font-size: 25px;
  letter-spacing: -0.025em;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
  border: 0;
  background-color: #ffffff;
  color: #495c6e;
  vertical-align: top;
  margin-top: 15px;
  box-sizing: border-box;
}
</style>
<style scoped src="@/styles_mobile/login.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
